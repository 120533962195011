import React, { useState, useEffect, useRef, useContext } from "react";
import City from "../models/City";
import ReactSelect from "react-select";

import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import * as Yup from "yup";
import { useFormik } from "formik";
import { deleteFile, getFile, uploadFile } from "../api/fileService";
import AlertContext from "../context/AlertContext/allertContext";
import { Progress } from "@material-tailwind/react";
import { fetchCities } from "../api/citiesService";
import { toogleOverflow } from "../utils/helpers/style_helpers";
import { Spinner } from "reactstrap";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const CityFormular = ({ setShowFormular, data, setSelectedCity, setdata }) => {
  const { showAlert } = useContext(AlertContext);

  const error = "";
  const filePondRef = useRef(null);

  const [files, setFiles] = useState([]);

  const [loading, setLoading] = useState(false);

  const [lat, setLat] = useState(46.76374291690988);
  const [lng, setLng] = useState(2.263165173084807);

  const [showFileRequiredError, setShowFileRequiredError] = useState(false);

  const [progress, setProgress] = useState(0);
  const [disponibleCities, setDisponibleCities] = useState([]);
  useEffect(() => {
    const filePond = filePondRef.current;
    const fetchImages = async (img) => {
      try {
        const response = await getFile(img);
        const image = response.data;
        const file = new File([image], img, { type: "image/jpeg" });
        setFiles([file]);
      } catch (error) {
        console.error(error);
      }
    };
    if (data !== undefined) {
      filePond.removeFiles();
      fetchImages(data.image);
      setLat(data.coordinates ? data.coordinates[0] : 0);
      setLng(data.coordinates ? data.coordinates[1] : 0);
    }

    const unsubscribe = City.getAllCities(callBack);

    toogleOverflow();
    return () => {
      unsubscribe();
      toogleOverflow();
    };
  }, []);
  const callBack = async (allData) => {
    var cities = await fetchCities();
    cities = cities ?? [];
    cities = cities?.filter((city) => {
      return !allData.some(
        (d) => d.name.toLowerCase() === city.value.toLowerCase()
      );
    });

    if (data && data.name) {
      cities?.unshift({
        value: data.name,
        label: data.name,
      });
    }
    setDisponibleCities(cities);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: data ? data.name : "",
      // longtitude: data ? (data.coordinates ? data.coordinates[1] : 0) : 0,
      // latitude: data ? (data.coordinates ? data.coordinates[0] : 0) : 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Veuillez entrer le nom de votre ville."),
      // longtitude: Yup.number()
      //   .max(180)
      //   .min(-180)
      //   .required("Veuillez entrer la longitude de votre ville."),
      // latitude: Yup.number()
      //   .max(90)
      //   .min(-90)
      //   .required("Veuillez entrer la latitude de votre ville."),
    }),
    onSubmit: async (values) => {
      if (!files.length) {
        setShowFileRequiredError(true);
      } else {
        if (loading) return;
        setShowFileRequiredError(false);

        setLoading(true);
        if (!data?.id) {
          let images = [];
          for (let i = 0; i < files.length; i++) {
            let image = await uploadFile(files[i], (progressEvent) => {
              const progressPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(progressPercentage);
            });
            console.log(image);
            images.push(image);
          }
          await City.create(values.name, images);
          showAlert("La ville a été creé avec succès.");
        } else {
          if (files[0].filename !== data.image) {
            try {
              await deleteFile(data.image);
            } catch (error) {}
            data.image = await uploadFile(files[0], (progressEvent) => {
              const progressPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(progressPercentage);
            });
          }
          data.name = values.name;
          const city = new City(data);
          await city.save();

          setSelectedCity(undefined);
          showAlert("La ville a été mise à jour avec succès.");
        }
        setLoading(false);
        setShowFormular(false);
      }
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
      action="#"
    >
      <div className="fixed h-full overflow-hidden p-5 z-30 top-0 w-full left-0">
        <div className="flex relative items-center overflow-hidden justify-center  h-full  text-center sm:block  ">
          {loading && (
            <div className="absolute w-full  h-full bg-gray-900 z-30 bg-opacity-40 flex justify-center">
              <Spinner
                children={"Chargement..."}
                className="h-10 w-10 text-main self-center"
              />
            </div>
          )}
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block relative align-center bg-white rounded-3xl text-left  
          shadow-xl transform transition-all overflow-y-auto max-h-[calc(100%-4rem)]     sm:align-middle sm:max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold lg:text-xl md:text-lg text-base text-orange-500">
                  {!!data?.id
                    ? "Modification d'une ville"
                    : "Ajout d'une ville"}
                </h2>
                <div
                  className="text-gray-400 bg-gray-100 p-2 rounded-full cursor-pointer"
                  onClick={() => {
                    setdata(undefined);
                    setShowFormular(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-7 h-7"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>

              <div className="flex lg:flex-row flex-col mb-3 gap-3">
                <div className="flex-1">
                  <label htmlFor="name" className="font-bold text-sm">
                    Ville <span className="text-red-500">*</span>
                  </label>

                  <ReactSelect
                    value={{ value: data?.name, label: data?.name }}
                    onChange={(e) => {
                      setdata({ ...data, name: e?.value });
                    }}
                    isMulti={false}
                    options={disponibleCities}
                    menuShouldScrollIntoView={true}
                    maxMenuHeight={150}
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.name}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="w-full">
                <label
                  htmlFor="address"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Image <span className="text-red-500">*</span>
                </label>
                <FilePond
                  ref={filePondRef}
                  server={{ load: null }}
                  files={files}
                  onupdatefiles={setFiles}
                  allowMultiple={true}
                  maxFiles={3}
                  name="files"
                  className="bg-white p-10"
                  allowFileTypeValidation={true}
                  acceptedFileTypes={["image/png", "image/jpeg", "image/jpg"]}
                />
                {showFileRequiredError ? (
                  <h1 className="text-red-500 font-semibold">
                    Aucune image sélectionnée. Veuillez choisir une image avant
                    de continuer.
                  </h1>
                ) : null}
              </div>
            </div>
            <div className="w-full">
              <Progress
                className="h-2"
                color="amber"
                value={progress}
                variant="filled"
              />
            </div>
            <div className="bg-gray-50 px-4 py-3 text-center">
              <button
                onClick={() => {
                  setdata(undefined);
                  setShowFormular(false);
                }}
                type="button"
                className="md:py-3 py-2 md:px-8 px-4 bg-gray-500 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-gray-700 mr-2"
              >
                Annuler
              </button>
              <button
                disabled={error ? null : loading ? true : false}
                className="md:py-3 py-2 md:px-10 px-5 bg-amber-400 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-amber-500"
                type="submit"
              >
                {error ? null : loading ? (
                  <h1 className="text-sm me-2"> Loading... </h1>
                ) : // <ComponentLoader /> //loader added
                !!data?.id ? (
                  "Mettre à jour"
                ) : (
                  "Créer"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CityFormular;
