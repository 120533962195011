import { db } from "../utils/firebase";
import {
  collection,
  addDoc,
  getDoc,
  deleteDoc,
  updateDoc,
  doc,
  getDocs,
} from "firebase/firestore";
import Visit from "./Visit";
import {
  createOne,
  deleteOne,
  getAll,
  getDocuments,
  updateOne,
} from "../utils/database";
import { formatDate } from "../utils/date";

class City {
  static collectionName = "cities";

  constructor({ id, name, image, creationDate }) {
    this.id = id;
    this.name = name;
    // 1 image
    this.image = image;
    this.creationDate = creationDate;
    // tabel of visits
    // remove
    //this.visitIds = visitIds;
    //this.visits = null;
  }
  static async create(name, image) {
    try {
      const data = {
        name: name.toLowerCase(),
        image,
        creationDate: formatDate(new Date()),
      };
      const docRef = await createOne(this.collectionName, data);
      const id = docRef.id;
      await updateOne(this.collectionName, id, {id})
      return { ...data, id };
    } catch (e) {
      throw e;
    }
  }

  static getAllCities(callBack) {
    return getAll(this.collectionName, callBack);
  }

  static getAllC(query = "") {
    return getDocuments(this.collectionName, query);
  }

  static async getById(id) {
    const cityRef = await getDoc(doc(collection(db, "cities"), id));

    if (cityRef.exists) {
      const cityData = cityRef.data();

      // Retrieve the associated Visit for this City
      const visit = await Visit.getById(cityData.visitId);

      return new City({ id, name: cityData.name, image: cityData.image });
    } else {
      throw new Error(`City with ID ${id} does not exist`);
    }
  }

  async save() {
    console.log(City.collectionName, this.id, {
      name: this.name?.toLowerCase(),
      image: this.image,
    });
    await updateOne(City.collectionName, this.id, {
      name: this.name?.toLowerCase(),
      image: this.image,
    });
  }

  async delete() {
    await deleteOne(City.collectionName, this.id);
  }

  // async loadVisit() {
  //     let data = []
  //     for (let id in this.visitIds) {
  //         const visit = await Visit.getById(id);
  //         data.push(visit);
  //     }
  //     this.visits = data;
  // }
}

export default City;
