import axios from "./axiosConfig";

export const fetchCities = async () => {
  return await axios
    .get("/cities")
    .then((response) => {
      return response?.data?.map(({ city }) => ({
        value: city?.toLowerCase(),
        label: city,
      }));
    })
    .catch((error) => {
      console.log(error);
    });
};
