import React, { useState, useEffect, useRef, useContext } from "react";

import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import * as Yup from "yup";
import { useFormik } from "formik";
import Point from "../models/Point";
import { deleteFile, getFile, uploadFile } from "../api/fileService";

import ReactSelect from "react-select";
import Visit from "../models/Visit";
import MapComponent from "./Map";
import AlertContext from "../context/AlertContext/allertContext";
import { Progress } from "@material-tailwind/react";
import { where } from "firebase/firestore";

import { Spinner } from "@material-tailwind/react";
import { calculateDistance } from "../utils/helpers/geo_helpers";
import { toogleOverflow } from "../utils/helpers/style_helpers";
import { getVideoDuration } from "../utils/helpers/video_helpers";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const PointForm = ({ setShowFormular, data, setSelectedPoint, setData }) => {
  const { showAlert } = useContext(AlertContext);
  const error = "";
  const [files, setFiles] = useState([]);
  const [video, setVideo] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedVisit, setSelectedVisit] = useState(undefined);
  const [visits, setVisits] = useState();

  const [showVisitError, setShowVisitError] = useState(false);

  const [showMap, setShowMap] = useState(false);
  const [link, setLink] = useState(null);

  const [lat, setLat] = useState(48.864716);
  const [lng, setLng] = useState(2.349014);

  const [showImageRequiredError, setShowImageRequiredError] = useState(false);
  const [showVideoRequiredError, setShowVideoRequiredError] = useState(false);

  const imageFilePondRef = useRef(null);
  const videoFilePondRef = useRef(null);

  const [imageProgress, setImageProgress] = useState(0);
  const [videoProgress, setVideoProgress] = useState(0);

  const [orderMaxValue, setOrderMaxValue] = useState(0);

  const onChange = async (val) => {
    const data = await Point.getAllPt([where("FFidVisit", "==", val.value)]);
    let dataF = [];
    data.forEach((doc) => {
      const id = doc.id;
      dataF.push(new Point({ id, ...doc.data() }));
    });
    dataF.sort(function (a, b) {
      return a.order - b.order;
    });
    setOrderMaxValue(
      dataF[dataF.length - 1] ? dataF[dataF.length - 1].order + 1 : 0
    );
    setSelectedVisit(val);
  };

  const [showLoader, setShowLoader] = useState(false);
  const isValidGoogleDriveLink = (link) => {
    const googleDriveRegex =
      /^https:\/\/drive\.google\.com\/file\/d\/[a-zA-Z0-9_-]+\/?$/;
    return googleDriveRegex.test(link);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: data ? data.name : "",
      // duration: data ? data.duration : '',
      description: data ? data.description : "",
      address: data ? data.address : "",
      // ordre: data ? data.order : 0,
      longtitude: data ? data.coordinates[1] : 0,
      latitude: data ? data.coordinates[0] : 0,
      link: data?.video?.includes("http") ? data?.video : null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Veuillez entrer le nom du point."),
      // duration: Yup.number().min(1).positive().required("Please Entrer point duration"),
      description: Yup.string().required(
        "Veuillez entrer la description du point."
      ),
      address: Yup.string().required("Veuillez entrer l'adresse du point."),
      link: Yup.string()
        .matches(
          // /^https:\/\/drive\.google\.com\/(?:file\/d\/|open\?id=)([a-zA-Z0-9_-]+)(\/(?:view|preview))?$/,

          /^https:\/\/drive\.google\.com\/(file\/d\/[^/?#]+|open\?id=[^/?#]+)(\/.*)?$/,
          "Uniquement les liens google drive"
        )
        .nullable(),
      // ordre: Yup.number()
      //     .integer()
      //     .positive().min(0).max(orderMaxValue)
      //     .required("Veuillez entrer le numéro de commande du point."),

      longtitude: Yup.number()
        .max(180)
        .min(-180)
        .required("Veuillez entrer la longitude de votre ville."),
      latitude: Yup.number()
        .max(90)
        .min(-90)
        .required("Veuillez entrer la latitude de votre ville."),
    }),
    onSubmit: async (values) => {
      console.log(data);

      if (!loading) {
        setLoading(true);
        if (!selectedVisit) {
          setShowVisitError(true);
        } else {
          setShowVisitError(false);
          if (!files.length) {
            setShowImageRequiredError(true);
          } else {
            setShowImageRequiredError(false);
            if (!video.length && !link) {
              console.log(!video.length);
              console.log(link);
              setShowVideoRequiredError(true);
            } else {
              setShowVideoRequiredError(false);
              if (!data) {
                let image = await uploadFile(files[0], (progressEvent) => {
                  const progressPercentage = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setImageProgress(progressPercentage);
                });
                let videoName;
                let duration = 0;
                if (video.length) {
                  videoName = await uploadFile(video[0], (progressEvent) => {
                    const progressPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setVideoProgress(progressPercentage);
                  });

                  const getDuration = (video) => {
                    return new Promise((resolve) => {
                      video.onloadedmetadata = () => {
                        resolve(video.duration);
                      };
                    });
                  };

                  const videoData = document.createElement("video");
                  const url = URL.createObjectURL(video[0].file);
                  videoData.src = url;

                  duration = !link ? await getDuration(videoData) : 0;

                  URL.revokeObjectURL(url);
                } else {
                  videoName = link;
                  try {
                    duration = await getVideoDuration(link);
                    console.log("duration");
                    console.log(duration);
                    console.log("duration");
                  } catch (e) {}
                }

                await Point.create(
                  values.name,
                  image,
                  videoName,
                  duration,
                  values.description,
                  [lat, lng],
                  values.address,
                  selectedVisit.value,
                  orderMaxValue
                );
                showAlert("Le point a été creé avec succès.");

                // await Visit.getOne(selectedVisit.value).then(async (doc) => {
                //   const visitData = doc.data();
                //   const visit = new Visit({
                //     id: doc.id,
                //     name: visitData.name,
                //     categoryIds: visitData.categoryIds,
                //     images: visitData.images,
                //     distance: visitData.distance,
                //     duration: visitData.duration,
                //     cityID: visitData.cityID,
                //     description: visitData.description,
                //     price: visitData.price,
                //     creationDate: visitData.creationDate,
                //   });

                //   var distance = 0;

                //   await Point.getAllPt([
                //     where("idVisit", "==", selectedVisit.value),
                //   ]).then(async ({ docs }) => {
                //     // const docs = data.docs;
                //     for (let index = 0; index < docs.length - 1; index++) {
                //       const firstPointDoc = docs[index];
                //       const secoundPointDoc = docs[index + 1];

                //       const firstPoint = new Point({
                //         id: firstPointDoc.id,
                //         ...firstPointDoc.data(),
                //       });
                //       const secoundPoint = new Point({
                //         id: secoundPointDoc.id,
                //         ...secoundPointDoc.data(),
                //       });

                //       distance += calculateDistance(
                //         firstPoint?.coordinates?.[0],
                //         firstPoint?.coordinates?.[1],
                //         secoundPoint?.coordinates?.[0],
                //         secoundPoint?.coordinates?.[1]
                //       );
                //     }
                //     console.log(distance);
                //     visit.distance = parseFloat(distance.toFixed(2));
                //     await visit.save();
                //   });
                // });
              } else {
                if (files[0].filename !== data.image) {
                  try {
                    await deleteFile(data.image);
                  } catch (e) {}

                  data.image = await uploadFile(files[0], (progressEvent) => {
                    const progressPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setImageProgress(progressPercentage);
                  });
                }
                let duration = data.duration;

                if (
                  (video.length && video[0].filename !== data.video) ||
                  data.video !== link
                ) {
                  if (!data?.video?.includes("http")) {
                    try {
                      await deleteFile(data.video);
                    } catch (e) {}
                  }
                  if (video.length) {
                    data.video = await uploadFile(video[0], (progressEvent) => {
                      const progressPercentage = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      );
                      setVideoProgress(progressPercentage);
                    });

                    const getDuration = (video) => {
                      return new Promise((resolve) => {
                        video.onloadedmetadata = () => {
                          resolve(video.duration);
                        };
                      });
                    };

                    const videoData = document.createElement("video");
                    const url = URL.createObjectURL(video[0].file);
                    videoData.src = url;

                    duration = await getDuration(videoData);
                    // duration = duration;
                  } else {
                    duration = await getVideoDuration(link);
                    data.video = link;
                    console.log("duration");
                    console.log(duration);
                    console.log("duration");
                  }
                }
                data.name = values.name;
                data.coordinates = [lat, lng];
                data.description = values.description;
                data.duration = duration;
                data.address = values.address;
                data.idVisit = selectedVisit.value;

                try {
                  await data.save();
                } catch (e) {
                  console.log(e);
                }
                setSelectedPoint(undefined);
                showAlert("Le point a été mise à jour avec succès.");
              }
              setShowFormular(false);
            }
          }
        }
        setLoading(false);
        //dispatch(loginUser(values, props.router.navigate));
      }
    },
  });

  useEffect(() => {
    toogleOverflow();
    const loadVisits = async () => {
      const querySnapshot = await Visit.getAllVs();
      let myData = [];
      querySnapshot.forEach((doc) => {
        myData.push({ value: doc.id, label: doc.data().name });
      });
      setVisits(myData);
      if (data) {
        let d = myData.filter((e) => e.value === data.idVisit);
        //setSelectedVisit(d);
        if (d.length > 0) onChange(d[0]);
        setLat(data.coordinates[0]);
        setLng(data.coordinates[1]);
      }
    };
    loadVisits();

    const filePond = imageFilePondRef.current;
    const fetchFile = async (name, isVideo) => {
      if (!name) return;
      try {
        const response = await getFile(name, (progressEvent) => {
          let progressPercentage = 0;
          if (isVideo) {
            progressPercentage = Math.round(
              (progressEvent.loaded * 100) / data.videoSize
            );
            // setVideoProgress(progressPercentage);
          } else {
            progressPercentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            // setImageProgress(progressPercentage);
          }
        });
        const image = response.data;
        const file = new File([image], name, {
          type: isVideo ? "video/*" : "image/jpeg",
        });
        if (isVideo) setVideo([file]);
        else setFiles([file]);
      } catch (error) {
        console.error(error);
      }
    };

    if (data) {
      console.log(link);
      filePond.removeFiles();
      fetchFile(data.image, false);
      if (!data.video.includes("http")) {
        console.log(link);

        fetchFile(data.video, true);
        setLink(null);
      } else {
        console.log(link);

        setVideoProgress(100);
        setLink(data.video);

        console.log(link);
      }
    }
    return () => {
      toogleOverflow();
    };
  }, []);

  const handleMapClick = (data) => {
    setLat(data.latLng.lat());
    setLng(data.latLng.lng());
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
      action="#"
    >
      <div className="fixed h-full overflow-hidden p-5 z-30 top-0 w-full left-0">
        {showMap && (
          <MapComponent
            lat={parseFloat(lat)}
            lng={parseFloat(lng)}
            setLat={setLat}
            setLng={setLng}
            setShowMap={setShowMap}
            onClick={handleMapClick}
          />
        )}
        <div className="flex relative items-center overflow-hidden justify-center  h-full  text-center sm:block  ">
          {(showLoader || loading) && (
            <div className="absolute w-full  h-full bg-gray-900 z-30 bg-opacity-40 flex justify-center">
              <Spinner
                children={"Chargement..."}
                className="h-10 w-10 text-main self-center"
              />
            </div>
          )}
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center bg-white rounded-3xl text-left  
            shadow-xl transform transition-all overflow-y-auto max-h-[calc(100%-4rem)]     sm:align-middle sm:max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white  px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold lg:text-xl md:text-lg text-base text-orange-500">
                  {data !== undefined
                    ? "Modification d'une point"
                    : "​Ajout d'une point"}
                </h2>
                <div
                  className="text-gray-400 bg-gray-100 p-2 rounded-full cursor-pointer"
                  onClick={() => {
                    setShowFormular(false);
                    setData(undefined);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-7 h-7"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>

              <div className="mb-3 flex lg:flex-row flex-col gap-3">
                <div className="lg:w-1/2 w-full">
                  <label
                    htmlFor="name"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Nom <span className="text-red-500">*</span>
                  </label>
                  <input
                    name="name"
                    id="name"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                    placeholder="Entrer le nom"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    // invalid={
                    //     validation.touched.name && validation.errors.name ? true : false
                    // }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.name}
                    </p>
                  ) : null}
                </div>

                <div className="lg:w-1/2 w-full">
                  <label className="text-gray-800 block mb-2 font-bold">
                    Visite <span className="text-red-500">*</span>
                  </label>
                  <ReactSelect
                    value={selectedVisit}
                    isMulti={false}
                    options={visits}
                    onChange={onChange}
                    menuShouldScrollIntoView={true}
                    maxMenuHeight={150}

                    // options={dissortbyMulti}
                    // classNamePrefix="js-example-disabled-multi mb-0"
                    // isDisabled={fa}
                  />
                  {showVisitError ? (
                    <p className="text-red-500 text-xs">
                      Aucune visite sélectionnée. Veuillez choisir une visite
                      avant de continuer.
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="description"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Description <span className="text-red-500">*</span>
                </label>
                <textarea
                  name="description"
                  className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                  placeholder="Entrer la description"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                  // invalid={
                  //     validation.touched.description && validation.errors.description ? true : false
                  // }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <p className="text-red-500 text-xs">
                    {validation.errors.description}
                  </p>
                ) : null}
              </div>

              <div className="mb-3">
                <label
                  htmlFor="address"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Adresse <span className="text-red-500">*</span>
                </label>
                <textarea
                  name="address"
                  className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                  placeholder="Entrer l'addresse"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.address}
                  // invalid={
                  //     validation.touched.address && validation.errors.address ? true : false
                  // }
                />
                {validation.touched.address && validation.errors.address ? (
                  <p className="text-red-500 text-xs">
                    {validation.errors.address}
                  </p>
                ) : null}
              </div>

              <div className="flex lg:flex-row flex-col mb-3">
                <div className="flex-1 lg:mr-2 lg:mb-0 mb-3">
                  <label
                    htmlFor="longtitude"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Latitude <span className="text-red-500">*</span>
                  </label>
                  <input
                    name="latitude"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                    placeholder="Entrer le latitude"
                    type="text"
                    onChange={(e) => {
                      setLat(e.target.value);
                      validation.handleChange(e);
                    }}
                    onBlur={validation.handleBlur}
                    value={lat || ""}
                    // invalid={
                    //     validation.touched.name && validation.errors.name ? true : false
                    // }
                  />
                  {validation.touched.latitude && validation.errors.latitude ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.latitude}
                    </p>
                  ) : null}
                </div>

                <div className="flex-1 lg:ml-2 lg:mb-0 mb-3">
                  <label
                    htmlFor="longtitude"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Longitude <span className="text-red-500">*</span>
                  </label>
                  <input
                    name="longitude"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                    placeholder="Entrer le longitude"
                    type="text"
                    onChange={(e) => {
                      setLng(e.target.value);
                      validation.handleChange(e);
                    }}
                    onBlur={validation.handleBlur}
                    value={lng || ""}
                  />
                  {validation.touched.longtitude &&
                  validation.errors.longtitude ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.longtitude}
                    </p>
                  ) : null}
                </div>
              </div>

              <h1
                className="mb-3 bg-gray-100 p-2 text-center hover:bg-gray-200 rounded-xl w-full cursor-pointer"
                onClick={() => setShowMap(true)}
              >
                selectionner la position
              </h1>

              <div className="w-full ">
                <label className="text-gray-800 block mb-2 font-bold">
                  Image <span className="text-red-500">*</span>
                </label>
                <FilePond
                  ref={imageFilePondRef}
                  files={files}
                  onupdatefiles={setFiles}
                  allowMultiple={false}
                  name="files"
                  className="bg-white p-10"
                  allowFileTypeValidation={true}
                  acceptedFileTypes={["image/png", "image/jpeg", "image/jpg"]}
                />
                {showImageRequiredError ? (
                  <p className="text-red-500 text-xs">
                    Aucune image sélectionnée. Veuillez choisir une image avant
                    de continuer.
                  </p>
                ) : null}
              </div>

              <div className="w-full">
                <label className="text-gray-800 block mb-2 font-bold">
                  Video <span className="text-red-500">*</span>
                </label>
                <div>
                  <FilePond
                    ref={videoFilePondRef}
                    files={video}
                    onupdatefiles={setVideo}
                    allowMultiple={false}
                    name="files"
                    disabled={!!link}
                    className="bg-white p-10"
                    allowFileTypeValidation={true}
                    acceptedFileTypes={["video/*"]}
                  />

                  <div className="flex -mt-2 mb-2">
                    <span className=" mx-auto ">-ou-</span>
                  </div>
                  <input
                    name="link"
                    id="link"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                    placeholder="Entrer le lien google drive"
                    type="text"
                    disabled={!!video?.length}
                    onChange={(e) => {
                      console.log(e);
                      setLink(e.target.value);
                      validation.handleChange(e);
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.link || ""}
                  />

                  {validation.touched.link && validation.errors.link ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.link}
                    </p>
                  ) : null}
                </div>
                {showVideoRequiredError ? (
                  <p className="text-red-500 text-xs">
                    Aucun video sélectionnée. Veuillez choisir un video ou
                    inserer un lien avant de continuer.
                  </p>
                ) : null}
              </div>
            </div>
            <div className="w-full">
              <Progress
                className="h-2"
                color="amber"
                value={
                  video.length
                    ? imageProgress * 0.2 + videoProgress * 0.8
                    : imageProgress
                }
                variant="filled"
              />
            </div>
            <div className="bg-gray-50 px-4 py-3 text-center">
              <button
                onClick={() => {
                  setShowFormular(false);
                  setData(undefined);
                }}
                type="button"
                className="md:py-3 py-2 md:px-8 px-4 bg-gray-500 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-gray-700 mr-2"
              >
                Annuler
              </button>
              <button
                color="success"
                disabled={error ? null : loading ? true : false}
                className="md:py-3 py-2 md:px-10 px-5 bg-amber-400 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-amber-500"
                type="submit"
              >
                {error ? null : loading ? (
                  <h1 className="text-sm me-2"> Loading... </h1>
                ) : data !== undefined ? (
                  "Mettre à jour"
                ) : (
                  "Créer"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PointForm;
