import React, { useState, useEffect, useRef, useContext } from "react";
import Category from "../models/Category";
import { baseImageURL } from "../api/axiosConfig";
import { FilePond } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import * as Yup from "yup";
import { useFormik } from "formik";
import { deleteFile, getFile, uploadFile } from "../api/fileService";
import AlertContext from "../context/AlertContext/allertContext";
import { Progress } from "@material-tailwind/react";
import { toogleOverflow } from "../utils/helpers/style_helpers";
import { Spinner } from "reactstrap";

const CategoryForm = ({
  setShowFormular,
  data,
  setSelectedCat,
  isConsulting,
  setIsconsulting,
}) => {
  const { showAlert } = useContext(AlertContext);

  const [loading, setLoading] = useState(false);
  const error = "";

  const [files, setFiles] = useState([]);
  const [showFileRequiredError, setShowFileRequiredError] = useState(false);

  const [progress, setProgress] = useState(0);

  const filePondRef = useRef(null);

  useEffect(() => {
    const filePond = filePondRef.current;
    const fetchImages = async (img) => {
      if (img === "") return;
      try {
        const response = await getFile(img);
        const image = response.data;
        const file = new File([image], img, { type: "image/jpeg" });
        setFiles([file]);
      } catch (error) {}
    };
    if (data) {
      console.log(data);
      filePond?.removeFiles();
      fetchImages(data.image);
    }

    toogleOverflow();
    return () => {
      toogleOverflow();
    };
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: data ? data?.name : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Entrer city name"),
    }),
    onSubmit: async (values) => {
      if (loading === false) {
        setLoading(true);
        if (files.length === 0) {
          setShowFileRequiredError(true);
        } else {
          setShowFileRequiredError(false);
          if (!data) {
            let image = await uploadFile(files[0], (progressEvent) => {
              const progressPercentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(progressPercentage);
            });
            await Category.create(values.name, image);
            showAlert("La théme a été créée avec succès.");
          } else {
            if (files[0].filename !== data.image) {
              try {
                await deleteFile(data.image);
              } catch (e) {}

              data.image = await uploadFile(files[0], (progressEvent) => {
                const progressPercentage = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                setProgress(progressPercentage);
              });
            }
            data.name = values?.name;
            await data.save();
            setSelectedCat(undefined);
            showAlert("Le théme a été mise à jour avec succès.");
          }
          setShowFormular(false);
        }
      }
      setLoading(false);
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
      action="#"
    >
      <div className="fixed h-full overflow-hidden p-5 z-30 top-0 w-full left-0">
        <div className="flex relative items-center overflow-hidden justify-center  h-full  text-center sm:block  ">
          {loading && (
            <div className="absolute w-full  h-full bg-gray-900 z-30 bg-opacity-40 flex justify-center">
              <Spinner
                children={"Chargement..."}
                className="h-10 w-10 text-main self-center"
              />
            </div>
          )}
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center bg-white rounded-3xl text-left  
            shadow-xl transform transition-all overflow-y-auto max-h-[calc(100%-4rem)]     sm:align-middle sm:max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white  px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold lg:text-xl md:text-lg text-base text-orange-500">
                  {isConsulting ? "Consulter un thème" : "Ajouter un thème"}
                </h2>
                <div
                  className="text-gray-400 bg-gray-100 p-2 rounded-full cursor-pointer"
                  onClick={() => {
                    setSelectedCat(undefined);
                    setShowFormular(false);
                    setIsconsulting(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-7 h-7"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>

              <label
                className="text-gray-800 block mb-2 font-bold"
                htmlFor="name"
              >
                Nom <span className="text-red-500">*</span>
              </label>

              {isConsulting ? (
                <p className="capitalize">{data?.name} </p>
              ) : (
                <div>
                  <input
                    name="name"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                    placeholder="Entrer le nom"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.name}
                    </p>
                  ) : null}
                </div>
              )}

              <label
                className="text-gray-800 block mb-2 font-bold mt-3"
                htmlFor="name"
              >
                Image <span className="text-red-500">*</span>
              </label>

              {isConsulting ? (
                <img
                  className=" h-52  w-full object-cover rounded-lg"
                  src={baseImageURL + data?.image}
                  alt={data?.name}
                />
              ) : (
                <div className="w-full">
                  <FilePond
                    ref={filePondRef}
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple={false}
                    name="files"
                    className="bg-white p-10"
                    allowFileTypeValidation={true}
                    acceptedFileTypes={["image/png", "image/jpeg", "image/jpg"]}
                  />
                  {showFileRequiredError ? (
                    <p className="text-red-500 text-xs">
                      Aucune image sélectionnée. Veuillez choisir une image
                      avant de continuer.
                    </p>
                  ) : null}
                </div>
              )}
            </div>
            <div className="w-full">
              <Progress
                className="h-2"
                color="amber"
                value={progress}
                variant="filled"
              />
            </div>
            {isConsulting ? (
              <div className="bg-gray-50 px-4 py-3 text-center">
                <button
                  onClick={() => {
                    setSelectedCat((e)=>undefined);
                    setShowFormular(false);
                    setIsconsulting(false);
                  }}
                  type="button"
                  className="md:py-3 py-2 md:px-8 px-4 bg-gray-500 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-gray-700 mr-2"
                >
                  Fermer
                </button>
              </div>
            ) : (
              <div className="bg-gray-50 px-4 py-3 text-center">
                <button
                  onClick={() => {
                    setSelectedCat(undefined);
                    setShowFormular(false);
                  }}
                  type="button"
                  className="md:py-3 py-2 md:px-8 px-4 bg-gray-500 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-gray-700 mr-2"
                >
                  Annuler
                </button>
                <button
                  color="success"
                  disabled={error ? null : loading ? true : false}
                  className="md:py-3 py-2 md:px-10  px-5 bg-amber-400 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-amber-500"
                  type="submit"
                >
                  {error ? null : loading ? (
                    <h1 className="text-sm me-2"> Loading... </h1>
                  ) : // <ComponentLoader /> //loader added
                  data?.id ? (
                    "Mettre à jour"
                  ) : (
                    "Créer"
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default CategoryForm;
