// import { db } from "../utils/firebase";
import Point from "./Point";
// import Category from "./Category";
import {
  createOne,
  deleteOne,
  getAll,
  getDocument,
  getDocuments,
  updateOne,
} from "../utils/database";
import { formatDate } from "../utils/date";
import { where } from "firebase/firestore";

class Visit {
  static collectionName = "visits";

  constructor({
    id,
    name,
    categories,
    images,
    distance,
    cityID,
    description,
    price,
    creationDate,
    duration,
  }) {
    this.id = id;
    this.name = name;
    this.categories = categories;
    this.images = images;
    this.distance = distance;
    this.duration = duration;
    this.cityID = cityID;
    this.description = description;
    this.price = price;
    this.creationDate = creationDate;
  }

  static async create({
    name,
    categories,
    images,
    distance,
    cityID,
    description,
    price,
    categoryIds,
    duration,
  }) {
    try {
      const data = {
        name: name.toLowerCase(),
        categoryIds: categoryIds,
        images: images,
        distance: distance,
        duration: duration,
        description: description,
        cityID: cityID,
        price: price,
        creationDate: formatDate(new Date()),
      };
      const docRef = await createOne(this.collectionName, data);
      const id = docRef.id;
      await updateOne(this.collectionName, id, { id });
      return { ...data, id };
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  static getAllVisits(callBack) {
    return getAll(this.collectionName, callBack);
  }

  static getAllVs(query = "") {
    return getDocuments(this.collectionName, query);
  }

  static getOne(id) {
    return getDocument(this.collectionName, id);
  }
  static fromJSON(id, data) {
    return new Visit(
      id,
      data.name,
      data.categoryIds,
      data.images,
      // data.duration,
      // data.adresse,
      data.distance,
      data.cityID,
      data.description,
      data.price
    );
  }

  async save() {
    await updateOne(Visit.collectionName, this.id, {
      name: this.name?.toLowerCase(),
      categoryIds: this.categories,
      images: this.images,
      // duration: this.duration,
      // adresse: this.adresse,
      distance: this.distance,
      description: this.description,
      cityID: this.cityID,
      price: this.price,
    });
  }

  async delete() {
    try {
      const data = await Point.getAllPt([where("idVisit", "==", this.id)]);
      let points = [];
      data.forEach((doc) => {
        const id = doc.id;
        points.push(new Point({ id, ...doc.data() }));
      });
      points.forEach(async (p) => {
        p.idVisit = "";
        console.log(p);
        // await p.save();
        await p.delete();
      });
      await deleteOne(Visit.collectionName, this.id);
    } catch (e) {
      console.log(e);
    }
  }
}

export default Visit;
