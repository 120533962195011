import React, { useRef } from "react";
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { googleMapsApiKey } from "../api/axiosConfig";

const MapComponent = ({
  setShowMap,
  onClick,
  lat,
  lng,
  setLat,
  setLng,
  setSelectedRow,
}) => {
  // const { isLoaded } = useJsApiLoader({
  //     id: 'google-map-script',
  //     googleMapsApiKey: "AIzaSyCo-WDvFtAc6tzSYqoOAakCzB2isXkQkn0"
  // })
  const defaultProps = {
    center: {
      lat: lat,
      lng: lng,
    },
    zoom: 14,
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  console.log(setLat);

  const searchBoxRef = useRef(null);

  const handlePlaceSelect = (result) => {
    setLat(result.geometry.location.lat());
    setLng(result.geometry.location.lng());
    //setSearchedLocation(result);
  };

  const handlePlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places.length > 0) {
      handlePlaceSelect(places[0]);
    }
  };

  return (
    <div className="absolute overflow-scrol z-50 top-0 w-screen h-screen left-0">
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-900 opacity-75" />
      </div>

      <div className="w-screen h-screen flex items-center relative">
        {/* <div onClick={() => setShowMap(false)} className='absolute h-8 w-8 z-40 text-amber-400 text-4xl top-10 right-20'>
                    <FiX />
                </div> */}
        <div className="h-1/2 md:w-1/2 w-full m-auto p-1 bg-white">
          <LoadScript
            googleMapsApiKey={googleMapsApiKey}
            libraries={["places"]}
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={defaultProps.center}
              zoom={14}
              onClick={onClick}
            >
              {setLat !== undefined && (
                <StandaloneSearchBox
                  onLoad={(ref) => {
                    searchBoxRef.current = ref;
                    ref.setBounds(
                      new window.google.maps.LatLngBounds(
                        new window.google.maps.LatLng(40.712776, -74.005974)
                      )
                    );
                  }}
                  onPlacesChanged={handlePlacesChanged}
                >
                  <input
                    type="text"
                    placeholder="entrez le nom"
                    style={{
                      boxSizing: `border-box`,
                      border: `1px solid transparent`,
                      width: `240px`,
                      height: `32px`,
                      padding: `0 12px`,
                      borderRadius: `3px`,
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.4)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      position: "absolute",
                      left: "50%",
                      top: "10px",
                      marginLeft: "-120px",
                    }}
                  />
                </StandaloneSearchBox>
              )}

              <Marker
                key={`${lat * lng}`}
                position={{
                  lat: lat,
                  lng: lng,
                }}
              />
            </GoogleMap>
          </LoadScript>
          <div className=" flex gap-3 ">
            <button
              onClick={() => {
                setShowMap(false);
                if (setSelectedRow !== undefined) {
                  setSelectedRow(undefined);
                }
              }}
              className=" mt-4 lg:w-1/4 w-full bg-amber-500 p-4 rounded-lg hover:bg-amber-600 text-white font-bold text-xl text-center cursor-pointer"
            >
              choisir
            </button>

            <button
              onClick={() => {
                setShowMap(false);
              }}
              className=" mt-4 p-4 rounded-lg hover:bg-gray-600 text-white font-bold  text-center cursor-pointer"
            >
              fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
