import React, { useState, useEffect, useRef, useContext } from "react";
import City from "../models/City";
import Visit from "../models/Visit";
import Category from "../models/Category";

import { FilePond, registerPlugin } from "react-filepond";

import * as Yup from "yup";
import { useFormik } from "formik";
import ReactSelect from "react-select";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { deleteFile, getFile, uploadFile } from "../api/fileService";
import AlertContext from "../context/AlertContext/allertContext";
import { Progress } from "@material-tailwind/react";
import { toogleOverflow } from "../utils/helpers/style_helpers";
import { Spinner } from "reactstrap";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const VisitForm = ({ setShowFormular, data, setSelectedVisit, setdata }) => {
  const { showAlert } = useContext(AlertContext);
  const [cats, setCats] = useState([]);
  const [selectedCat, setSelectedCat] = useState([]);

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(undefined);

  const [files, setFiles] = useState([]);

  const [showFileRequiredError, setShowFileRequiredError] = useState(false);
  const [showCategoryError, setShowCategoryError] = useState(false);
  const [showCityError, setShowshowCityError] = useState(false);

  const [loading, setLoading] = useState(false);

  const error = "";

  const filePondRef = useRef(null);

  const [progress, setProgress] = useState(0);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: data ? data.name : "",
      description: data ? data.description : "",
      distance: data ? data.distance : 0,
      duration: data ? data.duration : 0,
      price: data ? data.price : 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Entrer city name"),
      description: Yup.string().required("Please Entrer visits description"),
      // distance: Yup.number().positive().required("Please Entrer visit distance"),
      price: Yup.number().positive().required("Please Entrer visit price"),
      duration: Yup.number()
        .positive()
        .nonNullable()
        .required("Please Enter visit duration"),
    }),
    onSubmit: async (values) => {
      console.log("click submit");
      if (loading === false) {
        setLoading(true);
        if (files.length === 0) {
          setShowFileRequiredError(true);
        } else {
          // setShowFileRequiredError(false);
          // if (selectedCat.length === 0) {
          //   setShowCategoryError(true);
          // }
          // else
          // {
          setShowCategoryError(false);
          if (selectedCity === undefined) {
            setShowshowCityError(true);
          } else {
            setShowshowCityError(false);

            let categoryIds = [];
            selectedCat.forEach((element) => {
              categoryIds.push(element.value);
            });
            if (data === undefined) {
              let images = [];
              console.log("trying");
              for (let i = 0; i < files.length; i++) {
                try {
                  let image = await uploadFile(files[i], (progressEvent) => {
                    const progressPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setProgress(progressPercentage);
                  });
                  images.push(image);
                } catch (e) {
                  console.log(e);
                }
              }
              try {
                console.log("trying");
                await Visit.create({
                  name: values.name,
                  images: images,
                  distance: values.distance,
                  duration: values.duration,
                  description: values.description,
                  price: values.price,
                  cityID: selectedCity.value,
                  categoryIds: categoryIds,
                });
                showAlert("La visite a été creé avec succès.");
              } catch (e) {}
            } else {
              let filesNames = [];
              for (let i = 0; i < files.length; i++) {
                console.log(files[i]);
                filesNames.push(files[i].filename);
              }

              let toDelete = data.images.filter(
                (name) => !filesNames.includes(name)
              );
              let toAdd = data.images.filter((name) =>
                filesNames.includes(name)
              );
              let toUpload = filesNames.filter(
                (name) => !data.images.includes(name)
              );
              //let diff = filesNames.filter(name => !data.images.include(name));
              for (let i = 0; i < toDelete.length; i++) {
                try {
                  await deleteFile(toDelete[i]);
                } catch (error) {}
              }

              let uploadFiles = files.filter((file) =>
                toUpload.includes(file.filename)
              );
              let images = [...toAdd];
              console.log(uploadFiles);
              for (let i = 0; i < uploadFiles.length; i++) {
                setProgress(0);
                try {
                  let img = await uploadFile(files[i], (progressEvent) => {
                    const progressPercentage = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setProgress(progressPercentage);
                  });
                  images.push(img);
                } catch (e) {
                  console.log(e);
                }
              }
              data.name = values.name;
              // data.address = values.address;
              data.duration = values.duration;
              data.description = values.description;
              data.distance = values.distance;
              data.categories = categoryIds;
              data.images = images;
              data.cityID = selectedCity.value;
              data.price = values.price;
              await data.save();
              setSelectedVisit(undefined);
              showAlert("La visite a été mise à jour avec succès.");
            }
            setShowFormular(false);
            //dispatch(loginUser(values, props.router.navigate));
          }
          // }
        }
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const loadCategories = async () => {
      const querySnapshot = await Category.getAllCat();
      let myData = [];
      querySnapshot.forEach((doc) => {
        myData.push({ value: doc.id, label: doc.data().name });
      });
      setCats(myData);
      if (data !== undefined) {
        let cat = [];
        if (data.categories) {
          data.categories.forEach((id) => {
            let d = myData.filter((e) => e.value === id);
            cat.push(d[0]);
          });
          setSelectedCat(cat);
        }
      }
    };

    const loadCities = async () => {
      const querySnapshot = await City.getAllC();
      let myData = [];
      querySnapshot.forEach((doc) => {
        myData.push({ value: doc.id, label: doc.data().name });
      });
      setCities(myData);
      if (data !== undefined) {
        let d = myData.filter((e) => e.value === data.cityID);
        setSelectedCity(d[0]);
      }
    };
    loadCities();
    loadCategories();

    const filePond = filePondRef.current;
    const fetchImages = async (imgs) => {
      if (imgs === undefined || imgs.length === 0) return;
      try {
        let files = [];
        for (let i = 0; i < imgs.length; i++) {
          try {
            const response = await getFile(imgs[i]);
            const image = response.data;
            const file = new File([image], imgs[i], { type: "image/jpeg" });
            files.push(file);
          } catch (e) {}
        }
        setFiles(files);
      } catch (error) {
        console.error(error);
      }
    };

    if (data !== undefined) {
      filePond.removeFiles();
      fetchImages(data.images);
    }

    toogleOverflow();
    return () => {
      toogleOverflow();
    };
  }, []);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
      action="#"
    >
      <div className="fixed h-full overflow-hidden p-5 z-30 top-0 w-full left-0">
        <div className="flex relative items-center overflow-hidden justify-center  h-full  text-center sm:block  ">
          {loading && (
            <div className="absolute w-full  h-full bg-gray-900 z-30 bg-opacity-40 flex justify-center">
              <Spinner
                children={"Chargement..."}
                className="h-10 w-10 text-main self-center"
              />
            </div>
          )}
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center overflow-y-auto max-h-[calc(100%-4rem)]   bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold lg:text-xl md:text-lg text-base text-orange-500">
                  Ajout d'une visite
                </h2>
                <div
                  className="text-gray-400 bg-gray-100 p-2 rounded-full cursor-pointer"
                  onClick={() => {
                    setdata(undefined);
                    setSelectedVisit(undefined);
                    setShowFormular(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-7 h-7"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>

              <div className="mb-3 flex lg:flex-row flex-col gap-3">
                <div className="lg:w-1/2 w-full">
                  <label
                    htmlFor="name"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Nom <span className="text-red-500">*</span>
                  </label>
                  <input
                    name="name"
                    className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                    placeholder="Entrer le nom"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.name || ""}
                    // invalid={
                    //   validation.touched.name && validation.errors.name ? true : false
                    // }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.name}
                    </p>
                  ) : null}
                </div>
                <div className="lg:w-1/2 w-full">
                  <label
                    htmlFor="name"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Thèmes <span className="text-red-500">*</span>
                  </label>
                  <ReactSelect
                    value={selectedCat}
                    isMulti={true}
                    options={cats}
                    onChange={(val) => {
                      setSelectedCat(val);
                    }}
                    menuShouldScrollIntoView={true}
                    maxMenuHeight={200}
                    // options={dissortbyMulti}
                    // classNamePrefix="js-example-disabled-multi mb-0"
                    // isDisabled={fa}
                  />
                  {showCategoryError ? (
                    <p className="text-red-500 text-xs">
                      Aucune categorie sélectionnée. Veuillez choisir une ou
                      plusieur categorie avant de continuer.
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="mb-3">
                <label className="block font-bold text-sm mb-3" htmlFor="name">
                  Ville <span className="text-red-500">*</span>
                </label>
                <ReactSelect
                  value={selectedCity}
                  isMulti={false}
                  options={cities}
                  onChange={(val) => {
                    setSelectedCity(val);
                  }}
                  menuShouldScrollIntoView={true}
                  maxMenuHeight={200}
                />
                {showCityError ? (
                  <p className="text-red-500 text-xs">
                    Aucune ville sélectionnée. Veuillez choisir une ville avant
                    de continuer.
                  </p>
                ) : null}
              </div>

              <div className="mb-3 flex lg:flex-row flex-col gap-3">
                <div className="  w-full">
                  <label
                    htmlFor="price"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Prix <span className="text-red-500">*</span>
                  </label>
                  <div className="flex flex-row justify-center items-center mt-2 mb-3">
                    <input
                      name="price"
                      className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                      placeholder="Entrer le prix"
                      type="number"
                      //min={0}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.price}

                      // invalid={
                      //     validation.touched.duration && validation.errors.duration ? true : false
                      // }
                    />
                    <span className="px-3 font-bold text-orange-500">(€)</span>
                  </div>

                  {validation.touched.price && validation.errors.price ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.price}
                    </p>
                  ) : null}
                </div>

                <div className=" w-full">
                  <label
                    htmlFor="duration"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Durée <span className="text-red-500">*</span>
                  </label>
                  <div>
                    <div className="flex flex-row items-center mt-2 mb-3">
                      <input
                        name="duration"
                        className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4  rounded-full"
                        placeholder="Entrer le durée"
                        type="number"
                        min={0}
                        onChange={(e) => {
                          validation.handleChange(e);
                        }}
                        onBlur={validation.handleBlur}
                        value={validation.values.duration}
                      />
                      <span className="px-3 font-bold text-orange-500">
                        (s)
                      </span>
                    </div>
                  </div>
                  {validation.touched.duration && validation.errors.duration ? (
                    <p className="text-red-500 text-xs">
                      {validation.errors.duration}
                    </p>
                  ) : null}
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="description"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Description <span className="text-red-500">*</span>
                </label>
                <textarea
                  name="description"
                  className="w-full border-2 text-gray-500 outline-none border-gray-200 bg-gray-50 py-2 pl-4 rounded-md"
                  placeholder="Entrer la description"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                  // invalid={
                  //     validation.touched.description && validation.errors.description ? true : false
                  // }
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <p className="text-red-500 text-xs">
                    {validation.errors.description}
                  </p>
                ) : null}
              </div>

              <div className="w-full">
                <label
                  htmlFor="address"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Image <span className="text-red-500">*</span>
                </label>
                <FilePond
                  ref={filePondRef}
                  files={files}
                  onupdatefiles={setFiles}
                  allowMultiple={true}
                  maxFiles={3}
                  name="files"
                  className="bg-white p-10"
                  allowFileTypeValidation={true}
                  acceptedFileTypes={["image/png", "image/jpeg", "image/jpg"]}
                />
                {showFileRequiredError ? (
                  <p className="text-red-500 text-xs">
                    Aucune image sélectionnée. Veuillez choisir une image avant
                    de continuer.
                  </p>
                ) : null}
              </div>
            </div>
            <div className="w-full">
              <Progress
                className="h-2"
                color="amber"
                value={progress}
                variant="filled"
              />
            </div>
            <div className="bg-gray-50 px-4 py-3 text-center">
              <button
                onClick={() => {
                  setdata(undefined);
                  setSelectedVisit(undefined);
                  setShowFormular(false);
                }}
                type="button"
                className="py-3 px-8 bg-gray-500 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-gray-700 mr-2"
              >
                Annuler
              </button>
              <button
                color="success"
                disabled={error ? null : loading ? true : false}
                className="py-3 px-10 bg-amber-400 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-amber-500"
                type="submit"
              >
                {error ? null : loading ? (
                  <h1 className="text-sm me-2"> Loading... </h1>
                ) : data !== undefined ? (
                  "Mettre à jour"
                ) : (
                  "Créer"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default VisitForm;
