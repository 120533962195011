import React, { useState, useEffect } from "react";
import City from "../models/City";
import Category from "../models/Category";

import { registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { baseImageURL } from "../api/axiosConfig";
import { toogleOverflow } from "../utils/helpers/style_helpers";

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

const ShowVisitForm = ({
  setShowFormular,
  data,
  setSelectedVisit,
  setdata,
}) => {
  const [selectedCat, setSelectedCat] = useState([]);

  const [selectedCity, setSelectedCity] = useState(undefined);

  useEffect(() => {
    const loadCategories = async () => {
      const querySnapshot = await Category.getAllCat();
      let myData = [];
      querySnapshot.forEach((doc) => {
        myData.push({ value: doc.id, label: doc.data().name });
      });

      if (data !== undefined) {
        let cat = [];
        if (data?.categories) {
          data?.categories.forEach((id) => {
            let d = myData?.filter((e) => e.value === id);
            cat.push(d[0]);
          });
          setSelectedCat(cat);
        }
      }
    };

    const loadCities = async () => {
      const querySnapshot = await City.getAllC();
      let myData = [];
      querySnapshot.forEach((doc) => {
        myData?.push({ value: doc.id, label: doc.data().name });
      });

      if (data !== undefined) {
        let d = myData?.filter((e) => e.value === data?.cityID);
        setSelectedCity(d[0]);
      }
    };
    loadCities();
    loadCategories();

    toogleOverflow();
    return () => {
      toogleOverflow();
    };
  }, []);

  return (
    <div>
      <div className="fixed overflow-hidden h-full z-30 top-0 w-full left-0">
        <div className="flex items-center justify-center h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            className="inline-block align-center overflow-y-auto max-h-[calc(100%-4rem)]   bg-white rounded-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold lg:text-xl md:text-lg text-base text-orange-500">
                  Consulter une Visite
                </h2>
                <div
                  className="text-gray-400 bg-gray-100 p-2 rounded-full cursor-pointer"
                  onClick={() => {
                    setShowFormular(false);
                    setdata(undefined);
                    setSelectedVisit(undefined);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-7 h-7"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </div>

              <div className="mb-3 flex lg:flex-row flex-col gap-3">
                <div className="lg:w-1/2 w-full">
                  <label
                    htmlFor="name"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Nom
                  </label>
                  <p className=" whitespace-pre-line ">{data?.name}</p>
                </div>
                <div className="lg:w-1/2 w-full">
                  <label
                    htmlFor="name"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Catégories
                  </label>
                  <p className=" capitalize ">
                    {selectedCat?.map((cat) => cat?.label)?.join(", ") ?? "-"}{" "}
                  </p>
                </div>
              </div>
              <div className="mb-3">
                <label className="block font-bold text-sm mb-3" htmlFor="name">
                  City
                </label>
                <p className="  capitalize ">{selectedCity?.label ?? "-"}</p>
              </div>

              <div className="mb-3 flex lg:flex-row flex-col gap-3">
                <div className="lg:w-1/2 w-full">
                  <label
                    htmlFor="duration"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Prix
                  </label>
                  <div className="flex flex-row  items-center mt-2 mb-3">
                    <p>
                      {data?.price}{" "}
                      <span className=" font-bold text-orange-500">(€)</span>
                    </p>
                  </div>
                </div>

                <div className="lg:w-1/2 w-full">
                  <label
                    htmlFor="duration"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Distance
                  </label>
                  <div>
                    <div className="flex flex-row items-center mt-2 mb-3">
                      <p>
                        {data?.distance}{" "}
                        <span className=" font-bold text-orange-500">(Km)</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="lg:w-1/2 w-full">
                  <label
                    htmlFor="duration"
                    className="text-gray-800 block mb-2 font-bold"
                  >
                    Durée
                  </label>
                  <div>
                    <div className="flex flex-row items-center mt-2 mb-3">
                      <p>{data?.duration ?? 0} </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="description"
                  className="text-gray-800 block mb-2 font-bold"
                >
                  Description
                </label>
                <p>{data?.description}</p>
              </div>

              <div className="w-full">
                <img
                  className=" h-52  w-full object-cover rounded-lg"
                  src={baseImageURL + data?.images}
                  alt={data?.name}
                />
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 text-center">
              <button
                onClick={() => {
                  setdata(undefined);
                  setSelectedVisit(undefined);
                  setShowFormular(false);
                }}
                type="button"
                className="py-3 px-8 bg-gray-500 text-white font-semibold lg:text-lg text-sm rounded-xl hover:bg-gray-700 mr-2"
              >
                fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowVisitForm;
