import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import Point from "../models/Point";
import PointForm from "./pointForm";
import MapComponent from "./Map";
import { deleteFile } from "../api/fileService";
import AlertContext from "../context/AlertContext/allertContext";
import SuppDialog from "./suppDialog";
import { where } from "firebase/firestore";

import LoadingSpinner from "./loadingSpinner";

import eyeIcon from "../assets/icons/show.svg";
import editIcon from "../assets/icons/edit.svg";
import deleteIcon from "../assets/icons/delete.svg";
import { convertSecondsToHMS } from "../utils/helpers/formaters";
import ShowPointForm from "./showPointForm";
const PointsTable = () => {
  const [showFormular, setShowFormular] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState(undefined);
  const [showPoint, setShowPoint] = useState(false);

  const [data, setData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);

  const [showMap, setShowMap] = useState(false);

  const { showAlert } = useContext(AlertContext);

  const [loding, setLoding] = useState(true);

  const [open, setIsOpen] = useState(false);

  const columns = [
    {
      name: (
        <span className=" text-lg whitespace-nowrap font-semibold">Nom</span>
      ),
      selector: (row) =>
        row?.name?.charAt(0).toUpperCase() + row?.name?.slice(1),
      sortable: true,
    },

    {
      name: (
        <span className=" text-lg whitespace-nowrap font-semibold">Durée</span>
      ),
      selector: (row) => parseFloat(row.duration),
      cell: (row) => convertSecondsToHMS(row.duration),
      sortable: true,
    },
    {
      name: (
        <span className=" text-lg whitespace-nowrap font-semibold">
          Adresse
        </span>
      ),
      selector: (row) => row.address,
      sortable: true,
    },

    {
      name: (
        <span className=" text-lg whitespace-nowrap font-semibold mx-auto">
          Actions
        </span>
      ),
      sortable: false,

      cell: (row) => {
        return (
          <div className="flex flex-row gap-4 mx-auto">
            <img
              src={eyeIcon}
              alt="show"
              className="text-gray-500 hover:text-blue-500 text-2xl  cursor-pointer"
              onClick={() => {
                setSelectedPoint(row);

                setShowPoint(true);
              }}
            />
            <img
              src={editIcon}
              alt="edit"
              className="text-gray-500 hover:text-blue-500 text-2xl  cursor-pointer"
              onClick={() => {
                setSelectedPoint(row);
                setShowFormular(true);
              }}
            />
            <img
              src={deleteIcon}
              alt="delete"
              className="text-red-500 hover:text-red-800 text-2xl cursor-pointer"
              onClick={() => {
                setIsOpen(true);
                setSelectedPoint(row);
              }}
            />
          </div>
        );
      },
    },
  ];

  const callBack = (data) => {
    data = data.map((pointData) => new Point(pointData));
    setData(data);
    setDisplayedData(data);
    setLoding(false);
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        color: "white",
        backgroundColor: "#1F2937",
      },
    },
  };

  const onChange = async (e) => {
    let pointsData = data;
    pointsData = pointsData.filter(
      (v) =>
        v?.name?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
        v?.address?.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
        v?.description?.toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    setDisplayedData(pointsData);
  };
  useEffect(() => {
    const unsubscribe = Point.getAllPoints(callBack);
    return () => unsubscribe();
  }, []);

  return (
    <div className="h-full pr-1  px-5 " key="table">
      {open && (
        <SuppDialog
          open={open}
          text={"Êtes-vous sûr de vouloir supprimer cette point ?"}
          setIsOpen={setIsOpen}
          setRow={setSelectedPoint}
          onConfirme={async () => {
            const data = await Point.getAllPt([
              where("idVisit", "==", selectedPoint.idVisit),
              where("order", ">", selectedPoint.order),
            ]);
            let dataF = [];
            data.forEach((doc) => {
              const id = doc.id;
              dataF.push(new Point({ id, ...doc.data() }));
            });
            dataF.forEach(async (point) => {
              point.order = point.order - 1;
              await point.save();
            });

            try {
              await deleteFile(selectedPoint.image);
              selectedPoint.delete();
            } catch (e) {}
            try {
              await deleteFile(selectedPoint.video);
              selectedPoint.delete();
            } catch (e) {
              if (e.request.status === 500) {
                //row.delete();
                //showAlert('La visite a été supprimée avec succès.');
              }
            }
            await selectedPoint.delete();
            setIsOpen(false);
            setSelectedPoint(undefined);
            showAlert("Le point a été supprimée avec succès.");
          }}
        />
      )}
      {showMap && (
        <MapComponent
          lat={selectedPoint.coordinates[0]}
          lng={selectedPoint.coordinates[1]}
          setShowMap={setShowMap}
          setSelectedRow={setSelectedPoint}
        />
      )}
      {showFormular && (
        <PointForm
          setShowFormular={setShowFormular}
          data={selectedPoint}
          setSelectedPoint={setSelectedPoint}
          setData={setSelectedPoint}
        />
      )}

      {showPoint && (
        <ShowPointForm
          setShowPoint={setShowPoint}
          data={selectedPoint}
          setSelectedPoint={setSelectedPoint}
          setData={setSelectedPoint}
        />
      )}
      <div className="h-full w-full p-7 ">
        {loding ? (
          <div className="h-40 flex items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="bg-white max-w-full lg:p-7 pl-1 rounded-3xl  ">
            <div className="flex flex-col">
              {" "}
              <div className="mb-4 flex lg:flex-row flex-col justify-between items-center">
                <h1 className="lg:text-3xl md:text-xl text-lg font-bold leading-tight text-gray-900">
                  Points
                </h1>

                <div className="flex lg:w-1/3 w-full py-2">
                  <input
                    onChange={onChange}
                    className="bg-white appearance-none border-2 border-amber-500 rounded-xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-amber-600"
                    id="inline-searcg"
                    type="text"
                    placeholder="Recherche"
                  />
                </div>
                <div className="flex items-center py-2">
                  <button
                    onClick={() => setShowFormular(true)}
                    className="flex items-center rounded-full md:text-base text-sm pl-4 pr-1 py-1 border border-transparent leading-5 font-medium  text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:shadow-outline"
                  >
                    Créer un nouveau point
                    <div className="bg-white py-2 px-2 text-amber-500 rounded-full text-center items-center text-lg ml-3">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.2111 0C13.8454 0 15.6154 1.84915 15.6154 4.60109V11.0143C15.6154 13.7662 13.8454 15.6154 11.2111 15.6154H4.40426C1.76999 15.6154 0 13.7662 0 11.0143V4.60109C0 1.84915 1.76999 0 4.40426 0H11.2111ZM11.2111 1.08945H4.40426C2.39097 1.08945 1.08945 2.46723 1.08945 4.60109V11.0143C1.08945 13.1482 2.39097 14.5259 4.40426 14.5259H11.2111C13.2251 14.5259 14.5259 13.1482 14.5259 11.0143V4.60109C14.5259 2.46723 13.2251 1.08945 11.2111 1.08945ZM7.80769 4.5955C8.10838 4.5955 8.35242 4.83953 8.35242 5.14022V7.25571L10.4707 7.25585C10.7713 7.25585 11.0154 7.49989 11.0154 7.80057C11.0154 8.10126 10.7713 8.3453 10.4707 8.3453L8.35242 8.34515V10.4618C8.35242 10.7625 8.10838 11.0065 7.80769 11.0065C7.50701 11.0065 7.26297 10.7625 7.26297 10.4618V8.34515L5.14472 8.3453C4.84331 8.3453 4.6 8.10126 4.6 7.80057C4.6 7.49989 4.84331 7.25585 5.14472 7.25585L7.26297 7.25571V5.14022C7.26297 4.83953 7.50701 4.5955 7.80769 4.5955Z"
                          fill="#FEBD2A"
                        />
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
              <DataTable
                columns={columns}
                noDataComponent="Aucun résultat trouvé"
                data={displayedData}
                pagination
                customStyles={customStyles}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default PointsTable;
